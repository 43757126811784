<template>
  <div class="cart">
    <page-title title="Корзина" description="Здесь отображаются выбранные вами товары" />

    <div class="container">
      <n-loader :loading="$var('load')" />
      <div class="content">
        <div v-if="cart.length">
          <div v-for="group in cart" :key="group.sellerType + group.seller.id" class="group">
            <div class="title-wrap">
              <div v-if="group.sellerType === 'store'">
                <span>Магазин:</span><b>{{ group.seller.title }}</b>
              </div>
              <div v-else>
                <span>Цветы с рук:</span><b>{{ group.seller.fullName }}</b>
              </div>
            </div>
            <div v-for="item in group.items" :key="item.itemId" class="product">
              <n-link :to="{name: 'products.index', params: {id: item.product.id}}">
                <div class="photo">
                  <img :src="item.product.avatar" alt="">
                </div>
              </n-link>

              <div class="info">
                <div class="top">
                  <div>
                    <div class="product-title">
                      <n-link :to="{name: 'products.index', params: {id: item.product.id}}">{{ item.product.title }}</n-link>
                    </div>
                    <div v-if="item.product.code" class="code">Артикул: {{ item.product.code }}</div>
                    <star-rating read-only :star-size="12" :rating="item.product.rating" :increment="0.01"></star-rating>
                  </div>

                  <div class="delete-wap">
                    <n-button class="flat" icon="trashBasket" @click="remove(item.itemId)"></n-button>
                  </div>
                </div>
                <div class="bottom">
                  <div v-if="item.product.storeId" class="count-wrap">
                    <n-button class="minus" :disabled="item.count === 1" @click="changeCount(item.itemId, item.count -1)">
                      -
                    </n-button>
                    <input type="number" :value="item.count" @change="(v) => changeCount(item.itemId, v.target.value)" />
                    <n-button class="plus" :disabled="item.count >= item.product.count" @click="changeCount(item.itemId, item.count +1)">
                      +
                    </n-button>
                  </div>
                  <div v-else></div>
                  <div class="price">{{ $n.numberFormat(item.product.cost) }} ₸</div>
                </div>
              </div>
            </div>

            <div class="total-sum">
              Общая сумма: <b>{{ $n.numberFormat(group.sum) }} ₸</b>
            </div>

            <n-link :to="{name: 'orders.create', params: {id: group.seller.id, type: group.sellerType}}">
              <n-button class="main-btn blue">
                Оформить заказ
              </n-button>
            </n-link>
          </div>
        </div>

        <div v-else class="info">В Вашей корзине пока нет товаров...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      cart: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.cart.get().then((response) => {
        this.cart = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    changeCount(itemId, count) {
      this.$var('load', true)
      $api.app.cart.count(itemId, count).then(() => {
        this.load()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    remove(itemId) {
      this.$var('load', true)
      $api.app.cart.remove(itemId).then(() => {
        this.load()
        $app.store.action('app.cartCount')
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.cart {
  .info{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  .title-wrap {
    font-size: 16px;
    color: #464646;
    margin-bottom: 10px;

    b {
      margin-left: 6px;
    }
  }

  .group {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 58px;
    margin-bottom: 34px;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .product {
    background: #FFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    min-height: 140px;
    margin-bottom: 10px;
    display: flex;
    padding: 15px;

    .photo {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

    .product-title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 3px;
      color: #242424;
      a {
        color: inherit;
      }
    }

    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .code {
        font-size: 12px;
        color: #BABABA;
        margin: 6px 0 4px;
      }

      &::v-deep .vue-star-rating-rating-text {
        font-size: 12px;
        color: #BABABA;
      }
    }

    .bottom {
      border-top: 1px solid #F2F2F2;
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .count-wrap {
        display: flex;
        align-items: center;

        input {
          border: 1px solid #F2F2F2;
          height: 26px;
          width: 30px;
          text-align: center;
        }
      }

      &::v-deep .n-button {
        background: #F2F2F2;
        width: 23px;
        height: 26px;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 0;
        font-size: 17px;
        letter-spacing: -0.08px;
        color: #7E8389;

        &.plus {
          border-radius: 0 10px 10px 0;
        }

        &.minus {
          border-radius: 10px 0 0 10px;
        }
      }

      .price {
        font-size: 14px;
        color: #404040;
        font-weight: 700;
      }
    }
  }

  .total-sum {
    font-size: 14px;
    color: #BABABA;
    text-align: right;
    margin: 12px 0;

    b {
      font-size: 16px;
      line-height: 19px;
      color: #404040;
      margin-left: 10px;
      font-weight: 700;
    }
  }

  .main-btn {
    width: 100%;
    border: 1px solid #2A82D3;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 500;
    font-size: 12px;
    height: 40px;
    background: #FFF;
    color: #2A82D3;
    transition: all .2s ease;
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.blue {
      background: #2A82D3;
      color: #FFF;
    }
  }
}
</style>
